import React from 'react'
import { Outlet } from 'react-router-dom'

import FooterBottom from '../business/footer/FooterBottom';
import Header from '../blog/header';

import '../../assets/css/blog.css'

import { Helmet } from 'react-helmet-async';

const BlogsPageLayout = () => {
  return (
    <div className='layout'>
      <Helmet>
        <meta property="og:title" content="Order & Pickup Services for Restaurants Cafés .. | DishDash" />
        <meta property="og:description" content="Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside, In-Store, Table Ordering, and Counter Pickup." />
        <meta property="og:image" content="https://dishdash.io/assets/dd3-B891fmrY.png" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <main className='blogs-main'>
        <Outlet />
      </main>
      <div className='blogs-footer'>
        <FooterBottom />
      </div>
    </div>
  )
}

export default BlogsPageLayout