function BenefitCard({ service: { title, description, img } }) {
  return (
    <div className="service-increase-row">
      <div className="service-increase-item">
        <img className="swipeimage" src={img} alt="thumb" />
        <div className="service-increase-title">
          <h3>{title}</h3>
        </div>
        <div className="service-increase-body">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default BenefitCard;
