import { Link } from "react-router-dom";
import './index.css'
function HeaderButton() {
	return (
		<section className="wrapper">
			<div className="top">DishDash Blog</div>
			<div className="bottom" aria-hidden="true">DishDash Blog</div>
		</section>
	);
}

export default HeaderButton;
