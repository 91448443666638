/* eslint-disable for-direction */
// import useMenu from "../../../hooks/useMenu";
// import Navbar from "../../../../../components/common/menu/Navbar";
// import useMenu from "../../../../../hooks/useMenu";
import useMenu from "../../../hooks/useMenu";
import HeaderLogo from "../../business/header/HeaderLogo";
import Navbar from "../../common/menu/Navbar";
// import Navbar from "../../common/menu/Navbar";
import HeaderButton from "./HeaderButton";
// import HeaderLogo from "./HeaderLogo";

function Header() {
	const {
		toggleMenu,
		handleMenu,
		setToggleMenu,
	} = useMenu();

	return (
		<header className="site-header header-section header1 dark-bg" id="sticky-menu">
			<div className="container">
				<nav className="navbar site-navbar">
					<HeaderLogo />
					<div className="menu-block-wrapper">
						<div className={`menu-overlay ${toggleMenu ? "active" : ""}`} onClick={handleMenu}></div>
						<Navbar
							toggleMenu={toggleMenu}
							handleMenu={handleMenu}
						/>
					</div>
					<HeaderButton />
					<div className="mobile-menu-trigger light" onClick={() => setToggleMenu(true)}>
						<span></span>
					</div>
				</nav>
			</div>
		</header>
	);
}

export default Header;
