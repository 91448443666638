
export default function AuthorAvatar({ post }) {
  return (
    <div>
      <img
        className="author-avatar"
        src={`${post.metadata.author?.metadata.image?.imgix_url}?w=100&auto=format,compression`}
        alt={post.title}
      />
    </div>
  );
}
