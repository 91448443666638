import { Outlet } from "react-router-dom";
import Footer from "../consumers/footer";
import Header from "../consumers/header";
import { Helmet } from "react-helmet-async";
function ConsumersPageLayout() {
	return (
		<>
			<Helmet>
				<title>Ultimate Convenience for Order & Pickup Options | DishDash</title>
				<meta name="description" content="Order ahead with DishDash for easy and convenient pickup options at your favourite restaurants, cafés, and local shops. Curbside Pickup | In-Store Pickup." />
				<meta property="og:title" content="Order & Pickup Services for Restaurants Cafés .. | DishDash" />
				<meta property="og:description" content="Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside, In-Store, Table Ordering, and Counter Pickup." />
				<meta property="og:image" content="https://dishdash.io/assets/dd3-B891fmrY.png" />
				<meta property="og:url" content={window.location.href} />
			</Helmet>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}

export default ConsumersPageLayout;
