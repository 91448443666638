import { useRef } from "react";
import emailjs from '@emailjs/browser';
import ArrowRight3Img from "../../../assets/images/icon/arrow-right3.svg";
import { useForm } from "react-hook-form";
import Field from "../../common/Field";

function MessageForm() {
	const form = useRef();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const submitForm = () => {
		emailjs
			.sendForm('service_pmz1vqa', 'template_xx2dkp6', form.current, 'sMP9mAm41zE697wbZ')
			.then(function () {
				alert('Your form has been submitted successfully!');
			}, function (error) {
				alert('Failed to send the form. Please try again.');
				console.log(error.text);
			});
	};

	return (
		<form ref={form} onSubmit={handleSubmit(submitForm)}>
			<div className="form-field">
				<Field error={errors.name}>
					<input
						{...register("name", { required: "Name is required." })}
						type="text"
						name="name"
						id="name"
						placeholder="Your Name *"
					/>
				</Field>
			</div>
			<div className="form-field">
				<Field error={errors['business-name']}>
					<input
						{...register("business-name", { required: "Business Name is required." })}
						type="text"
						name="business-name"
						id="business-name"
						placeholder="Your Business Name *"
					/>
				</Field>
			</div>
			<div className="form-field">
				<Field error={errors['business-email']}>
					<input
						{...register("business-email", { required: "Email is required." })}
						type="email"
						name="business-email"
						id="business-email"
						placeholder="Your business email address *"
					/>
				</Field>
			</div>
			<div className="form-field">
				<Field>
					<input
						{...register("business-website")}
						type="url"
						name="business-website"
						id="business-website"
						placeholder="Your business website"
					/>
				</Field>
			</div>
			<div className="form-field">
				<input
					{...register("phone-number")}
					type="text"
					name="phone-number"
					id="phone-number"
					placeholder="Phone Number"
				/>
			</div>
			<div className="form-field">
				<input
					{...register("pos")}
					type="text"
					id="pos"
					name="pos"
					placeholder="POS"
				/>
			</div>
			<div className="form-field">
				<input
					{...register("country")}
					type="text"
					id="country"
					name="country"
					placeholder="Country"
				/>
			</div>
			<div className="form-field">
				<textarea
					name="note"
					placeholder="Write your message here..."
				></textarea>
			</div>
			<button id="submit-btn" type="submit">
				Submit
				<span>
					<img src={ArrowRight3Img} alt="ArrowRight3Img" />
				</span>
			</button>
		</form>
	);
}

export default MessageForm;
