import LogoLargImg from "../../../assets/images/v3/logo-large.svg";
import { Link } from "react-router-dom";
function Footer() {
	return (
		<footer className="footer-section">
			<div className="container">
				<div className="footer-bottom three">
					<div className="row">
						<div className="d-block d-md-flex justify-content-between">
							<div className="copywright three">
								<p>&copy;Copyright 2024, All Rights Reserved by GeoMe</p>
							</div>
							<div className="eula d-block d-md-flex text-center" style={{ color: "#999" }}>
								<a
									href="https://geome-pub.s3.ap-southeast-2.amazonaws.com/DishDashPrivacyPolicyEULA.html"
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: "black", fontWeight: "bold" }}
								>Privacy Policy</a>
								<br className="d-block d-md-none" />
								<a
									href="https://geome-pub.s3.ap-southeast-2.amazonaws.com/DishDashToSEULA.html"
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: "black", fontWeight: "bold" }}
								>Terms & Conditions</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
