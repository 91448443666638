import { Link } from "react-router-dom";
import Star2Img from "../../../assets/images/v1/star2-orange.png";
function Content() {
  return (
    <div className="default-content text-center" style={{ position: "relative", height: "100%" }}>
      <h2>
        Flexible Solution for
        <br />
        <span>
          All Business Types
          <img src={Star2Img} alt="StarImg" className="d-none d-lg-inline" width={50} style={{ marginLeft: "20px" }} />
        </span>
      </h2>
      <p className="" style={{ maxWidth: "1000px", margin: "auto" }}>
        Our Curbside Pickup technology is available globally and caters to
        businesses of all sizes. Whether you run a pharmacy, restaurant,
        café, ghost kitchen, butcher shop, fresh produce market, fishmonger,
        or florist, DishDash has you covered. Our versatile solution is designed
        to meet the unique needs of various industries.
      </p>
      <br />
      <p>Start your free trial to experience the full benefits of DishDash Business.</p>
    </div>
  );
}

export default Content;