export const routes = [
  {
    title: "Businesses",
    href: "#",
  },
  {
    title: "Consumers",
    href: "consumers-page",
  },
  {
    title: "Blog",
    href: "posts",
  },
];
