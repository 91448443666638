function HeroContent() {
	return (
		<div className="hero-content3">
			<p>
				<span>Experience the ultimate convenience and save time!</span>
			</p>
			<h1>Order Pay & Pickup Orders The Easy Way</h1>
			<p>
				Place orders from home or at the store, pay,
				and choose from in-store pickup, curbside pickup, or table delivery.
			</p>
			<div className="hero-subscription">
				<button id="hero-subscription-btn" type="submit">
					<a href="https://dl.geome.io/u/QRyD" style={{ textDecoration: "none", color: "black" }}>
						<span className="label-up">Get The App</span>
						<span className="label-up">Get The App</span>
					</a>
				</button>
			</div>
		</div>
	);
}

export default HeroContent;
