import { Link } from "react-router-dom";
/* eslint-disable react/prop-types */
// import Dropdown from "./Dropdown";
function NavItem({ route, depthLevel, mobileSubMenu, handleSubMenu, mobileSubMenuSub, handleSubMenuSub }) {

	return (
		<li className="nav-item" data-id={route.href}>
			<Link to={`/${route.href}`} className={"nav-link-item"}>
				{route.title}
			</Link>
		</li>
	);
}

export default NavItem;
