import { Link } from "react-router-dom";
import Star2Img from "../../../assets/images/v1/star2-orange.png";

function Content() {
	return (
		<div className="default-content d-flex flex-column align-items-center w-100 text-center mb-5">
			<h2>
				Getting Started
				<br />
				<span>
					With DishDash
					<img src={Star2Img} alt="StarImg" className="d-none d-lg-inline" width={50} style={{ marginLeft: "20px" }} />
				</span>
			</h2>
			<p className="get-start-text" >
				Do you own or manage a restaurant, café, pharmacy,
				or any business that can benefit from advanced ordering
				and pickup technology solutions? Follow these easy steps
				to boost your sales, streamline your operations,
				and enhance your customer experience:
			</p>
		</div>
	);
}

export default Content;
