import { Link } from "react-router-dom";
import ArrowRightImg from "../../../assets/images/icon/arrow-right.svg";
function ServiceCard({ service: { title, description, icon, linkTitle } }) {
	return (
		<div className="col-lg-6">
			<div className="iconbox-wrap">
				<div className="iconbox-icon">
					<i className={`${icon}`}></i>
				</div>
				<div className="iconbox-data">
					<h3>{title}</h3>
					<p>{description}</p>
					{linkTitle && (
						<a className="btn" style={{ textDecoration: 'underline', color: '#fdfde1' }} href="https://web.dishdash.io/">Get The Consumer App</a>
					)}
				</div>
			</div>
		</div>
	);
}

export default ServiceCard;
