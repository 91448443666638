import FooterBottom from "./FooterBottom";
import FooterContent from "./FooterContent";
import MessageForm from "./MessageForm";

function Footer() {
	return (
		<footer className="footer-section dark-bg" id="footer">
			<div className="container">
				<div className="footer-top section-padding">
					<div className="row">
						<div className="col-lg-7">
							<FooterContent />
						</div>
						<div className="col-lg-5">
							<div className="form-wrap">
								<h3>Contact Us</h3>
								<MessageForm />
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="row">
						<FooterBottom />
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
