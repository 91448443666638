import LogoWhiteImg from "../../../assets/images/logo/dd3.png";
function FooterBottom() {
	return (
		<div className="d-block d-md-flex  justify-content-between">
			<div className="">
				<div className="copywright one">
					{/* <p>Privacy Policy</p>
					<p>Terms & Conditions</p> */}
					<p> &copy; Copyright 2024, All Rights Reserved by GeoMe</p>
				</div>
			</div>
			<div className="eula d-block d-md-flex text-center" style={{ color: "#999" }}>
				<a
					href="https://geome-pub.s3.ap-southeast-2.amazonaws.com/DishDashBusinessPrivacyPolicyEULA.html"
					target="_blank"
					rel="noopener noreferrer"
				>Privacy Policy</a>
				<br className="d-block d-md-none" />
				<a
					href="https://geome-pub.s3.ap-southeast-2.amazonaws.com/DishDashBusinessToSEULA.html"
					target="_blank"
					rel="noopener noreferrer"
				>Terms & Conditions</a>
			</div>
		</div>
	);
}

export default FooterBottom;
