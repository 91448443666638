import { createBucketClient } from "@cosmicjs/sdk";

const cosmic = createBucketClient({
  bucketSlug: import.meta.env.VITE_BUCKET_SLUG || "",
  readKey: import.meta.env.VITE_BUCKET_READ_KEY || "",
});

export default cosmic;

export async function getGlobalData() {
  try {
    const data = await Promise.resolve(
      cosmic.objects
        .findOne({
          type: "globals",
          slug: "header",
        })
        .props("metadata.site_title,metadata.site_tag")
        .depth(1)
    );
    const siteData = data.object;
    return Promise.resolve(siteData);
  } catch (error) {
    console.log("Oof", error);
  }
  return Promise.resolve({});
}

export async function getAllPosts() {
  try {
    const data = await Promise.resolve(
      cosmic.objects
        .find({
          type: "posts",
        })
        .props("id,type,slug,title,metadata,created_at")
        .depth(1)
    );
    const posts = await data.objects;
    return Promise.resolve(posts);
  } catch (error) {
    console.log("Oof", error);
  }
  return Promise.resolve([]);
}

export async function getPost(slug) {
  try {
    const data = await Promise.resolve(
      cosmic.objects
        .findOne({
          type: "posts",
          slug,
        })
        .props(["id", "type", "slug", "title", "metadata", "created_at"])
        .depth(1)
    );
    const post = await data.object;
    return post;
  } catch (error) {
    console.log("Oof", error);
  }
  return Promise.resolve({});
}

export async function getRelatedPosts(slug) {
  try {
    const data = await Promise.resolve(
      cosmic.objects
        .find({
          type: "posts",
          slug: {
            $ne: slug,
          },
        })
        .props(["id", "type", "slug", "title", "metadata", "created_at"])
        .sort("random")
        .depth(1)
    );
    const suggestedPosts = await data.objects;
    return Promise.resolve(suggestedPosts);
  } catch (error) {
    console.log("Oof", error);
  }
  return Promise.resolve([]);
}

export async function getAuthor(slug) {
  try {
    const data = await Promise.resolve(
      cosmic.objects
        .findOne({
          type: "authors",
          slug,
        })
        .props("id,title")
        .depth(1)
    );
    const author = await data.object;
    return Promise.resolve(author);
  } catch (error) {
    console.log("Oof", error);
  }
  return Promise.resolve({});
}

export async function getAuthorPosts(id) {
  try {
    const data = await Promise.resolve(
      cosmic.objects
        .find({
          type: "posts",
          "metadata.author": id,
        })
        .props(["id", "type", "slug", "title", "metadata", "created_at"])
        .sort("random")
        .depth(1)
    );
    const authorPosts = await data.objects;
    return Promise.resolve(authorPosts);
  } catch (error) {
    console.log("Oof", error);
  }
  return Promise.resolve([]);
}
