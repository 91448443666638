import { Link } from "react-router-dom";
import Tag from "./Tag";
import { sanitize } from "isomorphic-dompurify";
import ArrowRight from "./icons/ArrowRight";
import AuthorAvatar from "./author/AuthorAvatar";
import AuthorAttribution from "./author/AuthorAttribution";

export default function PostCard({ post }) {
  return (
    <div className="post-card">
      {post.metadata.hero?.imgix_url && (
        <Link to={`/posts/${post.slug}`}>
          <img
            className='post-card-image'
            src={`${post.metadata.hero?.imgix_url}?w=1400&auto=compression,format`}
            alt={post.title}
          />
        </Link>
      )}
      <h2 className='post-card-title'>
        <Link to={`/posts/${post.slug}`} className="post-card-title-link">{post.title}</Link>
      </h2>
      <div className="post-card-meta">
        <div className="post-card-author">
          <AuthorAvatar post={post} />
          <AuthorAttribution post={post} />
        </div>
        <div className="post-card-categories">
          {post.metadata.categories &&
            post.metadata.categories.map((category) => (
              <Tag key={category.title}>{category.title}</Tag>
            ))}
        </div>
      </div>
      <p
        className="post-card-teaser"
      >
        <span dangerouslySetInnerHTML={{
          __html: sanitize(post.metadata.teaser) ?? "",
        }} />
      </p>
      <div className="post-card-read-more">
        <Link to={`/posts/${post.slug}`} className="post-card-read-more-link">
          <div className="post-card-read-more-button">
            <span>Read article</span>
            <ArrowRight className="post-card-read-more-icon" />
          </div>
        </Link>
        <div className="post-card-tags">
          {post.metadata.categories &&
            post.metadata.categories.map((category) => (
              <Tag key={category.title}>{category.title}</Tag>
            ))}
        </div>
      </div>
    </div>
  );
}
