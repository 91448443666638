import { useState } from "react";
function useMenu() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState("");
  const [mobileSubMenuSub, setMobileSubMenuSub] = useState("");
  const [menuTitle, setMenuTitle] = useState("");

  const handleMenu = () => {
    setToggleMenu(false);
  };

  return {
    toggleMenu,
    setToggleMenu,
    handleMenu,
  };
}

export default useMenu;
